<template>
  <div class="orderdetail">
    <navbarTools :leftArrow="true" :fixed="true"></navbarTools>
    <div class="wapper bgc-F7F7F7 jd-order-detail-wrap">
      <div class="ml-15 mr-15">
        <div class="fs0 line-height-0 pr">
          <img class="w-100" :src="OSS_URL + '/shop/status.png'" />
          <div class="status-text fs16 c-fff">
            <span>{{ orderDetail?.orderStatusStr }}</span>
          </div>
        </div>
        <!-- 订单编号与时间 -->
        <div class="mt10 bgc-fff pl15 pr15 pt20 pb20 fs14 c-333 line-height-14">
          <div class="flex text-left">
            <div>订单编号：</div>
            <div class="flex-item text-left ml20">
              {{ orderDetail?.orderSn }}
            </div>
          </div>
          <div class="flex text-left mt15">
            <div>下单时间：</div>
            <div class="flex-item text-left ml20">
              {{ orderDetail?.createTime }}
            </div>
          </div>
        </div>

        <!-- 收货地址信息 -->
        <div class="mt10 bgc-fff pl15 pr15 pt19 pb17 flex text-left">
          <div class="w-18">
            <span class="c-FE6507 icon iconfont iconweizhi fs18"></span>
          </div>
          <div class="flex-item text-left ml18">
            <div class="fs16 line-height-16 c-333 font-weight">
              <span>{{ orderDetail?.address?.name }}</span>
              <span class="ml40">{{ orderDetail?.address?.mobile }}</span>
            </div>
            <div class="mt7 fs12 c-999 line-height-18">
              {{ orderDetail?.address?.provinceName }}
              {{ orderDetail?.address?.cityName }}
              {{ orderDetail?.address?.areaName }}
              {{ orderDetail?.address?.townName }}
              {{ orderDetail?.address?.address }}
            </div>
          </div>
        </div>

        <!-- 商品信息 -->
        <div class="pl15 pr15">
          <order
            :goods-data="goodsDeail"
            :msg-title-list="msgTitleList"
            :msg-value-list="msgValueList"
            :show-address="false"
            :addressDetail="orderDetail?.address"
            :goodsNum="orderDetail?.orderGoodsList[0].goodsNum + ''"
          >
            <template #btn>
              <div class="flex text-right mt30 mb10" v-if="orderDetail?.orderStatus == 1">
                <div class="jd-btn-order" @click="cancelOrderFn">取消订单</div>
                <div class="jd-btn-order active ml20" @click="goPay">去支付</div>
              </div>

              <div
                class="flex text-right mt30 mb10"
                v-if="orderDetail?.orderDeliveryList.length > 0"
              >
                <div class="jd-btn-order" @click="goLogisticsFn">查看物流</div>
              </div>
            </template>
          </order>
        </div>

        <!-- 客服信息 -->
        <div class="mt10 bgc-fff pl15 pr15 pt25 pb20 c-999 fs14 line-height-14">
          <div class="flex text-left">
            <div>京东售后客服电话</div>
            <div class="fs0 line-height-0 ml10">
              <van-icon
                name="phone-circle-o"
                class="callphone"
                @click="handlePhoneFn('4006066866')"
              />
            </div>
          </div>
          <div class="flex text-left mt20">
            <div>福利商城客服电话（周一至周五9:00-18:00）</div>
            <div class="fs0 line-height-0 ml10">
              <van-icon
                name="phone-circle-o"
                class="callphone"
                @click="handlePhoneFn('4006066866')"
              />
            </div>
          </div>
          <div class="flex text-left mt20">
            <div>京东家电安装电话</div>
            <div class="fs0 line-height-0 ml10">
              <van-icon
                name="phone-circle-o"
                class="callphone"
                @click="handlePhoneFn('4006561000')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbarTools from "@/components/navbarTools";
import order from "@/components/shop/order";
import { defineComponent, toRefs, reactive, getCurrentInstance, ref } from "vue";
import { ua } from "@/utils/ua";
import { useRoute, useRouter } from "vue-router";
import { orderDetail, cancelOrder } from "@/api/shop";
import { Dialog } from "vant";
import { Toast } from "vant";
export default defineComponent({
  components: {
    navbarTools,
    order,
  },
  setup(props) {
    const $route = useRoute();
    const $router = useRouter();
    const app = getCurrentInstance();
    const state = reactive({
      order_id: "",
      version: 2,
      msgTitleList: [
        { title: "商品总金额", value: "goods_coin" },
        { title: "订单运费", value: "freight_coin" },
        { title: "实付金额", value: "pay_coin", class: "c-FE6507" },
      ],
      msgValueList: {
        goods_coin: "",
        freight_coin: "",
        pay_coin: 100,
      },
      orderDetail: null,
      goodsDeail: {},
      OSS_URL: process.env.VUE_APP_OSS_URL,
      orderSn: $route.query.orderSn,
    });
    const handlePhoneFn = (tel) => {
      if (ua.isAndroidApp || ua.isIosApp) {
        app.proxy.$bridge.handleMethods("makePhonecall", { tel });
      } else {
        window.location.href = `tel://${tel}`;
      }
    };
    const orderDetailFn = async () => {
      let rs = await orderDetail({ orderSn: state.orderSn });
      if (rs.code === 0) {
        let obj = rs.data;
        obj.orderGoodsList[0].goodsThumbImageList = [
          obj.orderGoodsList[0].goodsThumbImage,
        ];
        state.orderDetail = obj;
        state.goodsDeail = obj.orderGoodsList[0];
        state.msgValueList.goods_coin =
          obj.orderSettlementAmount - obj.orderFreightAmount + "工钱币";
        state.msgValueList.pay_coin = obj.orderSettlementAmount + "工钱币";
        state.msgValueList.freight_coin = obj.orderFreightAmount + "工钱币";
      }
    };
    const goPay = () => {
      $router.push({
        path: "/jdshop/checkstand",
        query: {
          orderSn: state.orderSn,
          goodsChannel: state.goodsDeail.goodsChannel,
        },
      });
    };
    const cancelOrderFn = async () => {
      Dialog.confirm({
        title: "",
        message: "确定取消该订单吗?",
      })
        .then(async () => {
          // on confirm
          let rs = await cancelOrder({ orderSn: state.orderSn });
          if (rs.code === 0) {
            Toast(rs.data);
            $router.go(-1);
          }
        })
        .catch(() => {
          // on cancel
        });
    };
    const goLogisticsFn = () => {
      $router.push({
        path: "/jdshop/orderTracking",
        query: {
          orderSn: state.orderSn,
          expressName: state.orderDetail.orderDeliveryList[0].expressName,
          expressSn: state.orderDetail.orderDeliveryList[0].expressSn,
        },
      });
    };
    orderDetailFn();
    return {
      handlePhoneFn,
      cancelOrderFn,
      goPay,
      goLogisticsFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.orderdetail {
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
      .van-nav-bar__right .van-nav-bar__text {
        color: #333;
      }
    }
  }
  .jd-order-detail-wrap {
    .status-text {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 53px;
      margin: auto;
      line-height: 70px;
    }
    .callphone {
      color: #fe6507;
    }
  }
}
</style>
